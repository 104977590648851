<template>
  <div>
    <nav-bar :isNavWx="true" :bgc="'background:#fff'" :isFixed="true" :title="$t('售後詳情')"></nav-bar>
    <div class="box-main">
      <div class="goods-detail">
        <div class="goods-list">
          <div class="goods-item" v-for="(goods,index) in objData.goods.slice(0,orderIndex)" :key="index"
            @click="goproductDetails(goods)">
            <div class="imgbor">
              <img @error="imgOnError($event)" :src="getProductImageUrl(goods.picturePath,400)" alt="" />
              <div class="tags" v-if="goods.tag">{{ goods.tag }}</div>
            </div>
            <div class="goods-info">
              <div class="goods-name">
                {{ goods.productName }}
              </div>
              <div class="goods-tag" v-if="goods.fulltag">{{ goods.fulltag }}</div>
              <div class="goods-unit">{{ goods.standard }}</div>
              <div class="goods-price">
                <div class="price">{{ formatNumber(goods.unitPrice) }}</div>
                <div class="count">{{ $t("數量") }} {{ goods.amount }}</div>
              </div>
            </div>
          </div>
          <div class="viewall" @click="getViewallGoods('1')" v-if="objData.goods.length > 3 && unfold">
            {{ $t("查看所有") }}{{ objData.goods.length }}{{ $t("個商品") }}
            <img src="@/assets/icons/direction@2x.png" alt="" />
          </div>
          <div class="viewall" @click="getViewallGoods('2')" v-if="!unfold">
            {{ $t("收起") }}
            <img src="@/assets/icons/unfold.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAfterSaleDetail } from "@/api/user.js"
import mixins from "@/utils/mixins.js"
export default {
  name: 'afterSalesDetail',
  mixins: [mixins],
  data () {
    return {
      objData: {},
      orderIndex: 3,
      unfold: true,
      defaultImage: require("@/assets/image/default.png"),
    }
  },
  created () {
    const orderNo = this.$route.params.orderNo
    this.getData(orderNo)
  },
  methods: {
    async getData (data) {
      const res = await getAfterSaleDetail({ id: data })
      this.objData = res.data
    },
    // 跳转产品详情
    goproductDetails (data) {
      this.$router.push(`/product/${data.searchProductId}`)
    },
    getViewallGoods (type) {
      if (type === "1") {
        this.orderIndex = undefined
        this.unfold = false
      } else {
        this.orderIndex = 3
        this.unfold = true
      }
    },
    imgOnError (e) {
      e.target.src = this.defaultImage
    },
  }
}
</script>
<style lang="scss" scoped>
.box-main {
  padding-top: 50px;

  .goods-detail {
    background-color: #fff;
    border-radius: 16px 16px 0 0;
    padding: 16px;
    padding-top: 0;

    .Stash-title {
      font-weight: 500;
      font-size: 16px;
      color: #1a1a1a;
      padding-top: 16px;
    }

    .goods-item {
      margin-top: 16px;
      display: flex;

      .imgbor {
        border-radius: 12px;
        border: 1px solid #f8f8f8;
        overflow: hidden;
        position: relative;

        .tags {
          width: 32px;
          height: 20px;
          text-align: center;
          background: #E84935;
          border-radius: 1px 8px 1px 10px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 11px;
          color: #FFFFFF;
          line-height: 20px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      img {
        width: 120px;
        height: 120px;
      }

      .goods-info {
        margin-left: 16px;
        position: relative;

        .goods-name {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          width: 207px;
          font-size: 13px;
          color: #1a1a1a;
        }

        .goods-tag {
          display: inline-block;
          padding: 0 2px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 11px;
          color: #E84935;
          line-height: 16px;
          border-radius: 2px 2px 2px 2px;
          border: 1px solid rgba(232, 47, 47, 0.5);
        }

        .goods-unit {
          font-size: 12px;
          color: #999999;
          margin-top: 4px;
        }

        .goods-price {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: absolute;
          bottom: 0;
          width: 100%;

          .price {
            font-weight: 800;
            font-size: 16px;
            color: #f82207;
          }
        }
      }
    }

    .viewall {
      font-weight: 400;
      font-size: 15px;
      color: #3491fa;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      img {
        width: 16px;
        height: 16px;
        margin-left: 4px;
      }
    }
  }

}
</style>